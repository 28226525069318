import styled from 'styled-components';
import { themeGet } from 'styled-system';

const S = {
  layout: styled.div`
    width: 100%;
    padding: 0 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  context: styled.p`
    width: 100%;
    font-size: 16px;
    margin: 0 0 40px 0;
  `,
  description: styled.p`
    font-weight: ${themeGet('fontWeights.6', '700')};
    font-size: ${themeGet('fontSize.5', '18')}px;
    color: ${themeGet('colors.primary', '#00c68e')};
    margin: 0 0 12px 0;

    @media (max-width: 411px) {
      font-size: ${themeGet('fontSize.4', '16')}px;
    }
  `,
  link: styled.a`
    width: 220px;
    line-height: 60px;
    font-weight: ${themeGet('fontWeights.4', '500')};
    font-size: ${themeGet('fontSize.5', '18')}px;
    color: ${themeGet('colors.white', '#fff')};
    background: ${themeGet('colors.primary', '#00c68e')};
    border: 0;
    border-radius: 30px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    transition: all ease 250ms;

    &:hover {
      transform: scale(1.02);
    }

    @media (max-width: 768px) {
      width: 160px;
      line-height: 52px;
      border-radius: 26px;
      font-size: ${themeGet('fontSize.2', '14')}px;
    }
  `,
};

export default S;
