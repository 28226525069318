import React from 'react';

import sendGAEvent from '../../utils/event/core';
import S from './style';

interface Props {
  title: string;
  slug: string;
}

const DESIGN_ARTICLE = '/tech/banksalad-product-language-design/';

const JDFunnel: React.FC<Props> = ({ title, slug }) => {
  const categoryPath =
    // eslint-disable-next-line no-nested-ternary
    slug === DESIGN_ARTICLE ? 'design' : slug.includes('pnc') ? 'culture' : 'tech';

  const jdLinkUrl = `https://corp.banksalad.com/jobs/?category=${categoryPath}&utm_source=BS_WEB_blog&utm_medium=BS_blog_tech&utm_campaign=BS_blog_tech_contents&utm_content=${encodeURIComponent(
    slug
  )}`;

  const clickHandler = () => {
    // fbq('trackCustom', 'move_corpJobs');

    sendGAEvent('콘텐츠', '이동_채용Jobs', title)();
  };

  return (
    <S.layout>
      <S.description>보다 빠르게 뱅크샐러드에 도달하는 방법 🚀</S.description>
      <S.link href={jdLinkUrl} target="_blank" rel="noreferrer" onClick={clickHandler}>
        지원하기
      </S.link>
    </S.layout>
  );
};

export default JDFunnel;
