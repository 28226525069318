import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import React from 'react';
import {
  IoLogoFacebook,
  IoLogoLinkedin,
  IoLogoPinterest,
  IoLogoReddit,
  IoLogoTwitter,
} from 'react-icons/io';
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
} from 'react-share';
import urljoin from 'url-join';

import Head from '../components/Head';
import JDFunnel from '../components/JDFunnel';
import Layout from '../components/layout';
import PostCard from '../components/PostCard/postCard';
import PostDetails from '../components/PostDetails/postDetails';
import Sidebar from '../containers/Sidebar';
import {
  BlogDetailsContent,
  BlogPostDetailsWrapper,
  BlogPostFooter,
  PostShare,
  PostTags,
  RelatedPostItem,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostWrapper,
} from './templates.style';

function BlogPostTemplate(props: any) {
  const { data } = props;
  const { markdownRemark, allMarkdownRemark, site } = data;
  const post = markdownRemark;
  const {
    frontmatter: { title, date, cover, tags, category, noRecruitContent },
    fields: { slug },
    excerpt,
    html,
  } = post;
  const { edges } = allMarkdownRemark;
  const { siteUrl } = site.siteMetadata;
  const shareUrl = urljoin(siteUrl, slug);
  const isTechCategory = category === 'tech';
  const keywordSet = isTechCategory ? ['뱅크샐러드', '뱅크샐러드 기술 블로그'] : ['뱅크샐러드'];
  const keywords = [...keywordSet, ...tags];
  const coverImageURL = cover?.publicURL
    ? urljoin(siteUrl, cover.publicURL)
    : 'https://cdn.banksalad.com/app/meta/introduce-a/og_blog.png';

  return (
    <Layout>
      <Head
        title={`${title} | 뱅크샐러드`}
        description={excerpt}
        image={coverImageURL}
        keywords={keywords}
      />
      <BlogPostDetailsWrapper>
        <BlogDetailsContent>
          <PostDetails
            title={title}
            date={date}
            preview={cover && cover.childImageSharp.gatsbyImageData}
            description={html}
          />
          {!noRecruitContent && <JDFunnel title={title} slug={slug} />}
          <BlogPostFooter>
            {tags && (
              <PostTags className="post_tags">
                {tags.map((tag: string, index: number) => (
                  <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                    {`#${tag}`}
                  </Link>
                ))}
              </PostTags>
            )}
            <PostShare>
              <span>Share This:</span>
              <FacebookShareButton url={shareUrl} quote={excerpt}>
                <IoLogoFacebook />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={title}>
                <IoLogoTwitter />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl} title={title}>
                <IoLogoLinkedin />
              </LinkedinShareButton>
              <PinterestShareButton url={shareUrl} media={coverImageURL}>
                <IoLogoPinterest />
              </PinterestShareButton>
              <RedditShareButton url={shareUrl} title={title}>
                <IoLogoReddit />
              </RedditShareButton>
            </PostShare>
          </BlogPostFooter>
        </BlogDetailsContent>
        <Sidebar />
      </BlogPostDetailsWrapper>
      {edges.length !== 0 && (
        <RelatedPostWrapper>
          <RelatedPostTitle>Related Posts</RelatedPostTitle>
          <RelatedPostItems>
            {edges.map(({ node }: any) => (
              <RelatedPostItem key={node.fields.slug}>
                <PostCard
                  title={node.frontmatter.title || node.fields.slug}
                  url={node.fields.slug}
                  image={
                    node.frontmatter.cover && node.frontmatter.cover.childImageSharp.gatsbyImageData
                  }
                  tags={node.frontmatter.tags}
                />
              </RelatedPostItem>
            ))}
          </RelatedPostItems>
        </RelatedPostWrapper>
      )}
    </Layout>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        category
        noRecruitContent
        cover {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1170)
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___weight, frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: $tag } }, fields: { slug: { ne: $slug } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            cover {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 370, height: 220)
              }
            }
          }
        }
      }
    }
  }
`;
